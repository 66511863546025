import { QueryClient } from "@tanstack/react-query";
import { SaveRoleDialog } from "participant/components/SaveRoleDialog";
import { MAX_RESULTS_PER_PAGE, SavedRolesDataTable } from "participant/components/SavedRolesDataTable";
import { SavedSearchInput } from "participant/components/SavedSearchInput";
import { getIsUtahParticipant } from "participant/utils/getIsUtahParticipant.helpers";
import { getSavedSearchParams } from "participant/utils/getSavedSearchParams.helpers";
import { PostHog } from "posthog-js";
import * as React from "react";
import { Helmet } from "react-helmet";
import { LoaderFunctionArgs } from "react-router-dom";
import { PARTICIPANT_QUERIES } from "shared/api/participants";
import { USER_OPTIONS } from "shared/api/user/user";
import { PageActions, PageHeader, PageHeading } from "shared/components/PageLayout";
import { Button, ButtonIcon } from "shared/components/ds/Button";
import { LoadingContainer } from "shared/components/ds/Spinner";
import { IconUse } from "shared/components/ds/icons/Icon";
import { copy } from "shared/styles/text";
import { notFound } from "shared/util/notFound";
import { getSkip } from "shared/util/pagination.helpers";

export async function savedLoader(args: LoaderFunctionArgs, queryClient: QueryClient, postHog?: PostHog) {
  const searchParams = new URL(args.request.url).searchParams;
  const { term, orderBy, orderDir, page } = getSavedSearchParams(searchParams);
  const isUtahParticipant = await getIsUtahParticipant(queryClient);
  const canAccessUtahFeatures = postHog?.isFeatureEnabled("release-utah-program");
  const user = await queryClient.ensureQueryData(USER_OPTIONS.user());

  // For now, this feature is only for participants in the Utah program track
  if (!isUtahParticipant || !canAccessUtahFeatures) {
    return notFound();
  }

  queryClient.prefetchQuery(
    PARTICIPANT_QUERIES.savedRolesList(user.id.toFixed(), {
      params: {
        limit: MAX_RESULTS_PER_PAGE,
        skip: getSkip(page, MAX_RESULTS_PER_PAGE),
        term,
        orderBy: orderBy ?? undefined,
        orderDir: orderDir ?? undefined,
      },
    }),
  );

  return null;
}
export function SavedView() {
  return (
    <div className="content-container-full">
      <Helmet title="Saved" />
      <PageHeader>
        <div className="flex flex-col gap-3">
          <PageHeading>Saved</PageHeading>
          <p
            className={copy({
              variant: "16",
              color: "secondary",
            })}
          >
            Browse your lists of saved roles
          </p>
        </div>

        <PageActions>
          <SaveRoleDialog>
            <Button
              size={{
                initial: "sm",
                md: "default",
              }}
              prefix={
                <ButtonIcon>
                  <IconUse id="add-fill" />
                </ButtonIcon>
              }
            >
              Save New
            </Button>
          </SaveRoleDialog>
        </PageActions>
      </PageHeader>
      <div className="flex flex-col gap-6">
        <SavedSearchInput />
        <React.Suspense fallback={<LoadingContainer level="component" />}>
          <SavedRolesDataTable />
        </React.Suspense>
      </div>
    </div>
  );
}
