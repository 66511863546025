import * as React from "react";
import { twMerge } from "tailwind-merge";
import { tv, VariantProps } from "tailwind-variants";

interface InputProps extends Omit<React.ComponentPropsWithRef<"input">, "prefix">, VariantProps<typeof inputStyles> {
  prefix?: React.ReactElement;
  prefixStyles?: boolean;
  suffix?: React.ReactElement;
  suffixStyles?: boolean;
}
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, theme, disabled, readOnly, prefix, prefixStyles, suffix, suffixStyles, ...rest }, ref) => {
    const invalid = rest["aria-invalid"] === true || rest["aria-invalid"] === "true";
    const styles = inputStyles({ theme, className });

    return (
      <div
        className={twMerge(
          styles,
          disabled && "opacity-50",
          invalid && "border-ds-state-error ring-ds-red-light ring-2",
          readOnly && "border-ds-stroke-tertiary bg-ds-bg-foundation shadow-none",
        )}
      >
        {prefix && (
          <span
            className={prefixSuffixStyles({
              styles: prefixStyles,
              className: prefixStyles ? "border-r" : "-mr-3",
            })}
          >
            {prefix}
          </span>
        )}
        <input
          className="text-ds-text-primary placeholder:text-ds-text-placeholder read-only:text-ds-text-tertiary file:text-ds-text-inverse file:bg-ds-btn-secondary block w-full flex-1 bg-transparent px-3 py-1 file:mr-2 file:h-6 file:rounded file:border-0 file:px-2 file:text-xs file:font-medium disabled:cursor-not-allowed"
          ref={ref}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
        {suffix && (
          <span
            className={prefixSuffixStyles({
              styles: suffixStyles,
              className: suffixStyles ? "border-l" : "-ml-3",
            })}
          >
            {suffix}
          </span>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

const prefixSuffixStyles = tv({
  base: "h-full px-3 text-sm flex items-center text-ds-text-tertiary bg-transparent",
  variants: {
    styles: {
      true: "border-ds-stroke-tertiary bg-ds-bg-foundation",
    },
  },
});

export const inputStyles = tv({
  base: "flex h-10 text-sm items-center overflow-clip rounded-md border border-ds-stroke-tertiary bg-ds-field-1 transition-all focus-within:border-ds-neutral-400 focus-within:bg-ds-neutral-0 focus-within:ring-2 focus-within:ring-ds-stroke-secondary",
  variants: {
    theme: {
      foundation: "bg-ds-bg-foundation",
      layer1: "bg-ds-field-1",
      layer2: "bg-ds-field-2",
    },
  },
  defaultVariants: {
    theme: "layer1",
  },
});
