import { getCurrentUser } from "aws-amplify/auth";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export async function authenticationGuardLoader<TReturn>(args: LoaderFunctionArgs, cb?: () => TReturn) {
  try {
    // Check if the user is logged in. Will throw an error if the user is not.
    await getCurrentUser();

    if (typeof cb === "function") {
      return cb();
    }

    return null;
  } catch (_e) {
    // Redirect unauthenticated users to the login page.
    // Store the original URL so we can redirect back to it after login.
    const { request } = args;
    const url = new URL(request.url);
    const queryParams = url.pathname === "/" ? "" : `?redirectTo=${url.pathname}`;
    return redirect(`/login${queryParams}`);
  }
}
