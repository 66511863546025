import { tv } from "tailwind-variants";

export const heading = tv(
  {
    base: "tracking-tight m-0",
    variants: {
      variant: {
        "72": "text-7xl tracking-tighter",
        "60": "text-6xl",
        "48": "text-5xl",
        "36": "text-4xl",
        "30": "text-3xl",
        "24": "text-2xl",
        "20": "text-xl",
        "16": "text-base",
      },
      color: {
        primary: "text-ds-text-primary",
        secondary: "text-ds-text-secondary",
        tertiary: "text-ds-text-tertiary",
      },
      weight: {
        normal: "font-normal",
        medium: "font-medium",
        semibold: "font-semibold",
      },
      align: {
        left: "text-left",
        center: "text-center",
        right: "text-right",
      },
    },
    defaultVariants: {
      variant: "36",
      weight: "semibold",
      color: "primary",
    },
  },
  {
    responsiveVariants: true,
  },
);
