import { addMonths, differenceInWeeks, format, parseISO } from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export const weeksSince = (date) => {
  try {
    return differenceInWeeks(new Date(), parseISO(date));
  } catch (e) {
    return "N/A";
  }
};

/**
 * Takes the IANA timezone label and returns timezone abbreviation.
 *
 * Supported timezone abbreviations: MT, CT, ET, PT.
 *
 * @param {string} iana The IANA timezone label (ex: America/Los_Angeles).
 * @returns {string} The timezone abbreviation (ex: PT).
 */
const getAbbrevFromIANA = (iana) => {
  switch (iana) {
    case "America/New_York":
    case "America/Detroit":
      return "ET";
    case "America/Chicago":
      return "CT";
    case "America/Denver":
      return "MT";
    case "America/Los_Angeles":
    default:
      return new Date()
        .toLocaleDateString("en-US", {
          day: "2-digit",
          timeZoneName: "short",
        })
        .slice(4);
  }
};

export const showTimezoneLabel = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return getAbbrevFromIANA(timezone);
};

export const getEstimatedCloseOptions = () => {
  let current = new Date();
  current.setDate(1);

  const months = [];

  for (let i = 0; i < 3; i++) {
    if (i !== 0) {
      current = addMonths(current, 1);
    }

    months.push(current);
  }

  const options = [];

  months.forEach((month) => {
    const label = format(month, "MMMM yyyy");
    const value = format(month, "yyyy-MM-dd");

    options.push({
      label,
      name: label,
      value,
    });
  });

  options.push({ label: "Remove Est. Close", value: null });

  return options;
};

export const convertUTCtoTZ = (utcDateTime, fmtStr = "") => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDateTime = utcToZonedTime(utcDateTime, timezone);
  if (fmtStr.length > 0) {
    return formatInTimeZone(zonedDateTime, timezone, fmtStr);
  }
  return zonedDateTime;
};

/**
 * Converts a given zoned date-time to UTC format.
 *
 * @param {string | number | Date} zonedDateTime - The date-time string in a specific time zone.
 * @param {string} [fmtStr="yyyy-MM-dd HH:mm:ss"] - The format string for the output date-time. Default is `"yyyy-MM-dd
 *   HH:mm:ss"`
 * @returns {string} The converted date-time string in UTC format.
 */
export const convertTZtoUTC = (zonedDateTime, fmtStr = "yyyy-MM-dd HH:mm:ss") => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcZonedTime = zonedTimeToUtc(zonedDateTime, timezone);
  const utcDateTime = formatInTimeZone(utcZonedTime, "UTC", fmtStr);
  return utcDateTime;
};
