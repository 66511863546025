import { zodResolver } from "@hookform/resolvers/zod";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthError, confirmSignIn, getCurrentUser } from "aws-amplify/auth";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { LoaderFunctionArgs, Navigate, redirect, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getUserType } from "shared/api/user/user";
import { getRouteTreeRoot } from "shared/api/user/user.helpers";
import Grid from "shared/components/Grid";
import { headingOne } from "shared/components/H";
import LoginForm from "shared/components/LoginForm";
import { Button } from "shared/components/ds/Button";
import { toast } from "shared/components/ds/Toast/Toast";
import Input from "shared/components/forms/Input";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";
import { z } from "zod";

export async function twoFactorAuthLoader(args: LoaderFunctionArgs, queryClient: QueryClient) {
  try {
    const { request } = args;
    const user = await getCurrentUser();

    if (user) {
      try {
        const userType = await getUserType(queryClient);
        const routeTreeRoot = getRouteTreeRoot(userType);
        const url = new URL(request.url);
        const redirectTo = url.searchParams.get("redirectTo");

        // Redirect to home if user is already logged in
        return redirect(redirectTo ?? routeTreeRoot);
      } catch (_error) {
        return null;
      }
    }

    return null;
  } catch (_error) {
    return null;
  }
}

export function TwoFactorAuthView() {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") ?? null;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TwoFactorAuthFormInput>({
    mode: "onBlur",
    resolver: zodResolver(TwoFactorAuthFormSchema),
  });

  const mutation = useConfirmSignIn();

  function onSubmit(data: TwoFactorAuthFormInput) {
    const { otp } = data;
    mutation.mutate({
      challengeAnswer: otp,
    });
  }

  if (!state?.canAccessRoute) {
    /**
     * Here we access some temporary state (from `/login`) to ensure the user can access the route. We only want this
     * route accessible if they're going through the standard login flow
     */
    return <Navigate to={`/login${redirectTo ? `?${redirectTo}` : ""}`} replace />;
  }

  return (
    <LoginForm.Main>
      <OrangeDesignElement src="/images/breakline-orange-design-element.svg" />
      <TealDesignElement src="/images/breakline-teal-design-element.svg" />
      <Helmet title="Two Factor Authentication (2FA)" />
      <LoginForm.Content>
        <LoginForm.Form onSubmit={handleSubmit(onSubmit)}>
          <h1 className={headingOne({ fixed: true, className: "text-center" })}>Two Factor Authentication</h1>
          <Grid formGrid>
            <Input
              {...register("otp")}
              // @ts-expect-error Input component is not typed correctly
              type="text"
              label="One-time Password"
              placeholder="123456"
              errors={errors}
              span={2}
              required
            />

            <div className="col-span-2">
              <Button
                type="submit"
                isLoading={mutation.isPending}
                disabled={mutation.isPending}
                className="w-full"
                size="lg"
              >
                Submit
              </Button>
            </div>
          </Grid>
        </LoginForm.Form>
      </LoginForm.Content>
    </LoginForm.Main>
  );
}

const TwoFactorAuthFormSchema = z.object({
  otp: z.string().min(1, "Please enter your OTP code").min(6, "Please enter a valid OTP code"),
});
type TwoFactorAuthFormInput = z.input<typeof TwoFactorAuthFormSchema>;

function useConfirmSignIn() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  return useMutation({
    mutationFn: async ({ challengeAnswer }: { challengeAnswer: string }) => {
      await confirmSignIn({
        challengeResponse: challengeAnswer,
      });

      const currentAuthenticatedUser = await getCurrentUser();
      const userType = await getUserType(queryClient);
      const routeTreeRoot = getRouteTreeRoot(userType);

      return {
        user: currentAuthenticatedUser,
        routeTreeRoot,
      };
    },
    onSuccess: ({ routeTreeRoot }) => {
      navigate(redirectTo ?? routeTreeRoot, { replace: true });
    },
    onError: (error: unknown) => {
      if (error instanceof AuthError) {
        switch (error.name) {
          case "CodeMismatchException": {
            toast.error("Invalid OTP code. Please try again.");
            break;
          }
          case "EmptyChallengeResponse": {
            toast.error("No OTP code provided. Please try again.");
            break;
          }
          case "NotAuthorizedException": {
            toast.error("Login session has expired, please log in again.");
            return navigate(`/login?redirectTo=${redirectTo}`, {
              replace: true,
            });
          }
          default: {
            toast.error("Something went wrong, please try again.");
            break;
          }
        }
      } else {
        toast.error("Something went wrong, please try again.");
      }
    },
  });
}

const OrangeDesignElement = styled.img`
  position: absolute;
  left: -175px;
  top: 240px;
  height: 980px;
  z-index: -1;

  ${screen.below(
    bp.laptopSm,
    `
    height: 660px;
    left: -100px;
  `,
  )}

  ${screen.below(
    "1023px",
    `
    height: 530px;
    left: -100px;
  `,
  )}

  ${screen.below(
    bp.portrait,
    `
    height: 430px;
    top: unset;
    bottom: -300px;
    left: -85px;
  `,
  )}
`;

const TealDesignElement = styled.img`
  height: 490px;
  position: absolute;
  right: -332px;
  top: 360px;
  z-index: -1;

  ${screen.below(
    bp.laptopSm,
    `
    height: 382px;
    right: -240px;
  `,
  )}

  ${screen.below(
    "1090px",
    `
    height: 300px;
  `,
  )}


  ${screen.below(
    bp.portrait,
    `
    rotate: 180deg;
    transform: scaleX(-1);
    top: -156px;
    height: 250px;
    right: -148px;
  `,
  )}

  ${screen.below(
    bp.mobileSm,
    `
    right: -200px;
  `,
  )}
`;
