import { copy } from "shared/styles/text";
import { tv } from "tailwind-variants";

export const stepLayoutStyles = tv({
  slots: {
    header: "sticky top-0 z-10 flex items-baseline gap-4 bg-ds-bg-foundation px-5 pb-5 md:px-10",
    content: "flex flex-1 flex-col gap-5 px-5 md:px-10",
    description: copy({
      variant: {
        initial: "14",
        md: "16",
      },
      color: "secondary",
    }),
  },
});

export function StepLayoutPartText({ className, part, total }: { className?: string; part: number; total: number }) {
  return (
    <span
      className={copy({
        variant: "16",
        color: "tertiary",
        className,
      })}
    >
      {part} of {total}
    </span>
  );
}
