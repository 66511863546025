import { zodResolver } from "@hookform/resolvers/zod";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { getCurrentUser, signIn } from "aws-amplify/auth";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { redirect, useNavigate, useSearchParams } from "react-router-dom";
import { getUserType } from "shared/api/user/user";
import { getRouteTreeRoot } from "shared/api/user/user.helpers";
import Grid from "shared/components/Grid";
import { headingOne } from "shared/components/H";
import InlineLink from "shared/components/InlineLink";
import LoginForm from "shared/components/LoginForm";
import { Button } from "shared/components/ds/Button";
import Input from "shared/components/forms/Input";
import { bp } from "shared/styles/helpers";
import styled from "styled-components";
import screen from "superior-mq";
import { z } from "zod";

export const OrangeDesignElement = styled.img`
  position: absolute;
  left: -175px;
  top: 240px;
  height: 980px;
  z-index: -1;

  ${screen.below(
    bp.laptopSm,
    `
    height: 660px;
    left: -100px;
  `,
  )}

  ${screen.below(
    "1023px",
    `
    height: 530px;
    left: -100px;
  `,
  )}

  ${screen.below(
    bp.portrait,
    `
    height: 430px;
    top: unset;
    bottom: -300px;
    left: -85px;
  `,
  )}
`;

export const TealDesignElement = styled.img`
  height: 490px;
  position: absolute;
  right: -332px;
  top: 360px;
  z-index: -1;

  ${screen.below(
    bp.laptopSm,
    `
    height: 382px;
    right: -240px;
  `,
  )}

  ${screen.below(
    "1090px",
    `
    height: 300px;
  `,
  )}


  ${screen.below(
    bp.portrait,
    `
    rotate: 180deg;
    transform: scaleX(-1);
    top: -156px;
    height: 250px;
    right: -148px;
  `,
  )}

  ${screen.below(
    bp.mobileSm,
    `
    right: -200px;
  `,
  )}
`;

export async function loginLoader(queryClient: QueryClient) {
  try {
    const currentAuthenticatedUser = await getCurrentUser();
    if (currentAuthenticatedUser) {
      try {
        const userType = await getUserType(queryClient);
        const routeTreeRoot = getRouteTreeRoot(userType);

        // Redirect to home if user is already logged in
        return redirect(routeTreeRoot);
      } catch (_error) {
        return null;
      }
    }

    return null;
  } catch (_error) {
    return null;
  }
}

const LoginFormSchema = z.object({
  email: z.string().min(1, "Please enter your email address.").email({
    message: "Please enter a valid email address.",
  }),
  password: z.string().min(1, "Please enter your password."),
});

type LoginFormType = z.infer<typeof LoginFormSchema>;

export function LoginView() {
  const loginMutation = useLogin();
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<LoginFormType>({
    mode: "onBlur",
    resolver: zodResolver(LoginFormSchema),
  });
  const [submitErrors, setSubmitErrors] = useState<string | null>(null);

  /**
   * Send login form data to API
   *
   * @param {object} data - Username & password from form
   */
  const onSubmit = async (data: LoginFormType) => {
    loginMutation.mutate(
      { username: data.email, password: data.password },
      {
        onError: (error) => {
          if (error.name === "NotAuthorizedException") {
            setValue("password", "");
            setSubmitErrors("Your username and/or password is incorrect");
            return;
          }

          setValue("password", "");
          setSubmitErrors("An error occurred. Please try again.");
        },
      },
    );
  };

  return (
    <LoginForm.Main>
      <OrangeDesignElement src="/images/breakline-orange-design-element.svg" />
      <TealDesignElement src="/images/breakline-teal-design-element.svg" />
      <Helmet title="Login" />
      <LoginForm.Content>
        <LoginForm.Form onSubmit={handleSubmit(onSubmit)}>
          <h1 className={headingOne({ fixed: true })}>Log into BreakLine</h1>
          <Grid formGrid>
            <Input
              {...register("email")}
              // @ts-expect-error Input types aren't correct
              type="email"
              label="Email"
              placeholder="Email Address"
              defaultValue=""
              errors={errors}
              span={2}
            />

            <Input
              {...register("password")}
              // @ts-expect-error Input types aren't correct
              type="password"
              label="Password"
              placeholder="Password"
              errors={errors}
              defaultValue=""
              span={2}
            />

            <Grid.Item span={2}>
              <Button
                type="submit"
                isLoading={loginMutation.isPending}
                disabled={loginMutation.isPending}
                className="w-full"
                size="lg"
              >
                Submit
              </Button>
            </Grid.Item>

            <Grid.Item span={2}>
              <InlineLink href="/reset-password">Forgot Password</InlineLink>

              {submitErrors && <p style={{ color: "var(--error-red)" }}>{submitErrors}</p>}
            </Grid.Item>
          </Grid>
        </LoginForm.Form>
      </LoginForm.Content>
    </LoginForm.Main>
  );
}

function useLogin() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirectTo");
  const queryClient = useQueryClient();

  async function loginUser({ username, password }: { username: string; password: string }) {
    const output = await signIn({ username, password });

    if (output.isSignedIn) {
      const userType = await getUserType(queryClient);
      const routeTreeRoot = getRouteTreeRoot(userType);

      return {
        output,
        routeTreeRoot,
      };
    }

    return {
      output,
      routeTreeRoot: "/",
    };
  }

  return useMutation({
    mutationFn: loginUser,
    onSuccess: ({ output, routeTreeRoot }) => {
      if (output.nextStep && output.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
        /** Send the temp user to the 2fa flow */
        const queryParams = redirectUrl ? `?redirectTo=${redirectUrl}` : "";
        return navigate(`/2fa${queryParams}`, {
          replace: true,
          state: {
            canAccessRoute: true,
          },
        });
      }

      // Navigate to page they attempted to access before login
      // or home if no redirect param
      return navigate(redirectUrl ?? routeTreeRoot, {
        replace: true,
      });
    },
  });
}
